.Track {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(256, 256, 256, 0.8);

    height: 100px;
  }
  
  .Track-action {
    cursor: pointer;
    padding: .5rem;
    font-size: 1.05rem;
    transition: color .25s;
    border: 0px;
    background-color: rgba(0, 0, 0, 0);
    color: #fff;
  }
  
  .Track-action:hover {
    color: rgba(265, 265, 265, .5);
  }
  
  .Track-information {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 78px;
  }
  
  .Track-information h3 {
    margin-bottom: .22rem;
  
  }
  
  .Track-information p {
    font-size: 0.83rem;
    font-weight: 300;
    /* color: rgba(256, 256, 256, 0.8); */
    color: black; 
  }

    
  .Track-information-caru h3 {
    padding-top: 0.3rem;
    margin-bottom: .22rem;
    font-size: 3.83rem;
    color: black;
  }

  .albumImageToShow {

    padding-left: 0px;
  }
  
  .Track-information-caru p {
    padding-top: 0.7rem;
    font-size: 1.5rem;
    font-weight: 300;
    color: black;
  }

  .Track-information-caru-release p {
    padding-top: 0.2rem;
    font-size: 1.5rem;
    font-weight: 300;
    color: black;
  }

  .Artist-information {
    display: flex;
    flex-direction: column;

  }

  .Song-information {
    display: flex;
    flex-direction: column;

  }