.songBox {
    margin-left: 20px;
    margin-top: 20px;
    padding: 10px;
    width: 200px;
    height: 530px;
     display: flex;
     align-items: stretch;
     display: block;
border: 1px solid gray;
    z-index: 100;
    color: black;
    float: left;
    background-color: rgb(240, 240, 240);
    position: relative;
  }

  /* .albumImage {


  } */

.waitUntil {
  padding-top: 0px;
  color:rgb(102, 102, 102);
  
  /* hat schon rand */
  padding-left: 0px;
}


  .songRating{

    padding-top: 10px;
    font-weight: 700;
  }

  .playButton {

    color: white;
    text-decoration: none;
    padding: 20px;
   border-radius: 19px;
    font-weight: 800; 
    background-color: #1db954;
  }
  
  .playButtonSp {

    color: white;
    text-decoration: none;
    padding: 20px;
   border-radius: 19px;
    font-weight: 800; 
    background-color: #1db954;
  }
  .playButtonSp:hover {

    cursor: pointer;
    background-color:  #137536;
    color: rgb(3, 3, 3);
  }


  .playButton:hover {
    cursor: pointer;
    background-color:  #137536;
    color: rgb(3, 3, 3);
 
  }
  
  .songRow__info {
    margin-left: 2px;
   
  }
  
  .songRow__info > h1 {
    font-size: 16px;
  }
  
  .songRow__info > p {
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
    min-height: 64px;
    color: rgb(39, 39, 39);
  }
  
  .songRow__album {
    height: 40px;
    width: 40px;
  }
  