.overallReturnDisplayRatingsOfSongBox {

   margin-left: -10px;
      /* margin-top: 20px;
      padding: 5px; */
      width: 100%;
      height: auto;
      /* height: 150px; */
       display: flex;     /* geht unter Bild-Titel Reihe */
   
       /* align-items: stretch; */
       /* display: block; */
      /* border: 1px solid rgb(245, 245, 245); */
      z-index: 100;
      color: black;
      float: none;
      /* background-color: #00fff7; */
      /* position: relative; 
   */
    }

    .listOfSongRatingsMainTitle {

      margin-left: 10px;
         margin-top: -45px;
         margin-left: 223px;
         padding: 2px;
      
         height: auto;
         /* height: 150px; */
     /* geht unter Bild-Titel Reihe */
     display: flex;   
      
          /* align-items: stretch; */
          /* display: block; */
         /* border: 1px solid rgb(245, 245, 245); */
         z-index: 100;
         color: black;
         float: none;
         position: absolute;
   
         /* background-color: #ff0090; */

 
       }
       


       .listOfSongRatings {

         margin-left: -10px;
            margin-top: 20px;
            padding: 10px 0px 0px 25px;
            width: 100%;
            /* height: auto; */
            /* height: 150px; */
               /* geht unter Bild-Titel Reihe */
      
             /* align-items: stretch; */
             /* display: block; */
            /* border: 1px solid rgb(245, 245, 245); */
            /* z-index: 100; */
            color: black;
            float: none;
            /* background-color: #007bff; */
        
            /* position: relative; 
         */
          }
      
          

    .ratingLine {


     /* background-color: rgb(175, 241, 189); */

     padding: 5px 0px 5px 0px;
     width: 97%;
     float: none;
     display: flex;
     border-bottom: solid 1px #39883f;

    }