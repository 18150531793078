.footer {

  padding-left: 10px;
font-size: 15px;
color: rgb(71, 71, 71);
}

.footer__right * .MuiSlider-root {
  color: green;
}
.footer__left {
  display: flex;
  align-items: center;
  color: white;
  width: 300px;
  flex: 0.3;
}

.footer__green {
  color: #1ed15e;
}

.footer__center {
  flex: 0.4;
  max-width: 300px;
  padding: 0 100px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer__icon:hover,
.footer__green:hover {
  transition: transform 0.2s ease-in-out;
  transform: scale(1.2) !important;
}

.footer__right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  flex: 0.3;
}

