
  .Track-information {
    color: black;

    align-items: center;
  }


  .trackResults {
    
    color: white;
    text-decoration: none;
    padding: 3px;
  
   border-radius: 4px;
    font-weight: 800; 
    background-color: #1db954;
    width: 300px;
  
  }

  .trackResults:hover {
    cursor: pointer;
    background-color:  #137536;
    color: rgb(3, 3, 3);
 
  }