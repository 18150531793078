.feedbackBodySpons {
    padding: 30px;
    width: 100%;
  height: 100vh;
    overflow-y: overlay;
    flex: 1;
    color: rgb(34, 34, 34);
    /* background: linear-gradient(transparent, rgba(0, 0, 0, 1)); */
    /* background-color: rgb(91, 87, 115); */
    background-color: rgb(255, 183, 183);
    border: solid 1px white;
    align-items: baseline;
    padding-right: 30px;
  }
  
  .body > hr {
    border: 1px solid #746868;
    width: 98%;
    margin: 10px auto;

  }
/*   
  .body::-webkit-scrollbar {
    display: none;
  } */
  
  .takeMeBack {
    
    color: white;
    text-decoration: none;
    padding: 20px;
   border-radius: 19px;
    font-weight: 800; 
    background-color: #1db954;
    width: 400px;

  }
.redfont {

  color: rgba(201, 0, 0, 0.904);

}

  .songsFromSpot {
  
    /* new line */
    clear: both; 
    padding-top: 50px;
    color: rgb(46, 46, 46);
   
  padding-left: 50px;
  }


  .songsFromSpot > img {
    padding-top: 10px;
    height: 50px;
    opacity: 0.8;
  }


  .takeMeBack:hover {
    cursor: pointer;
    background-color:  #137536;
    color: rgb(3, 3, 3);
 
  }

 
  .messageText {

    min-height: 300px;
    padding-right: 50px;
  }

  .body__info {
    display: flex;
    align-items: flex-end;
    padding: 10px;
  }
  
  .body__shuffle {
    font-size: 80px !important;
    margin-left: 50px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .body__icons {
    display: flex;
    align-items: center;
  }
  
  .body__icons > .MuiSvgIcon-root {
    margin-right: 20px;
  }
  
  .body__shuffle:hover {
    transition: 100ms transform ease-in;
    transform: scale(1.08);
  }
  
  .body__background {
    position: absolute;
    top: 13vh;
    right: 0;
    opacity: 0.4;
    height: 80%;
    width: 80%;
    background-color: black;
  }
  
  .body__songs {
    z-index: 1;
    margin: 20px -30px;
  }
  
  .body__info > img {
    height: 20vw;
    margin: 0 20px;
    box-shadow: 0 4px 60px rgba(0, 0, 0, 0.5);
  }
  
  .body__infoText {
    flex: 1;
  }
  
  .body__infoText > h2 {
    font-size: 48px;
    margin-bottom: 10px;
  }
  
  .body__infoText > p {
    font-size: 14px;
  }
  
  .title__above__playlist {
   padding: 10px;
  
  }
  
  .testSeveralPlayers {

 padding-top: 570px;
    position: fixed;

  }