.body {
    padding: 30px;
    width: 100%;
  height: 100vh;
    overflow-y: overlay;
    flex: 1;
    color: rgb(34, 34, 34);
    background-color: rgb(255, 255, 255);
    border: solid 1px white;
    align-items: baseline;
    font-size: 15px;
    }

    
    .body::-webkit-scrollbar {
      display: none;
    }
    
    .body__info {
      display: flex;
      align-items: flex-end;
      padding: 10px;
    }
    
    .body__shuffle {
      font-size: 80px !important;
      margin-left: 50px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    
    .body__icons {
      display: flex;
      align-items: center;
    }
    
    .body__icons > .MuiSvgIcon-root {
      margin-right: 20px;
    }
    
    .body__shuffle:hover {
      transition: 100ms transform ease-in;
      transform: scale(1.08);
    }
    
    .body__background {
      position: absolute;
      top: 13vh;
      right: 0;
      opacity: 0.4;
      height: 80%;
      width: 80%;
      background-color: black;
    }
    
    .body__songs {
      z-index: 1;
      margin: 20px -30px;
    }
    
    .body__info > img {
      height: 20vw;
      margin: 0 20px;
      box-shadow: 0 4px 60px rgba(0, 0, 0, 0.5);
    }
    
    .body__infoText {
      flex: 1;
    }
    
    .body__infoText > h2 {
      font-size: 48px;
      margin-bottom: 10px;
    }
    
    .answer {
  
      font-weight: lighter;
    }
  
    .body__infoText > p {
      font-size: 12px;
    }
    
    .title__above__playlist {
     padding: 10px;
    
    }
    
    