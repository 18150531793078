.SearchBar {
  width: 360px;
    /* display: flex; 
   flex-direction: column;  */
    /* align-items: center;
    padding-top: 6.94rem;
    margin-bottom: 6.33rem;
    float: left; */
    /* display: inline; */
    margin: 0 auto;
  

  }
  
  .SearchBar input {
    /* justify-content: center; */
    width: 250px;
    padding: .88rem 0;
    border: 1px solid rgb(8, 136, 83);
    border-radius: 3px;
    /* margin: 0 auto; */
    margin-bottom: 2.22rem;
    color: #010c3f;
    text-align: center;
    font-size: 1rem;

  }
  
  .SearchBar input:focus {
    outline: none;
  }
  
  .SearchButton {
    color: white;
    text-decoration: none;
    padding: 15px;
   border-radius: 19px;
    font-weight: 800; 
    background-color: #1db954;
    width: 90px;
    margin-left: 10px;
    /* cursor: pointer;
    width: 8.11rem;
    padding: .77rem 0;
    border-radius: 54px;
    background-color: #010c3f;
    text-align: center;
    font-size: .833rem;
    transition: background-color .25s;
    border: 0px;
    color: #fff;
    font-weight: 500; */
  }
  
  .SearchButton:hover {
    /* background-color: rgba(108, 65, 233, .7); */
    cursor: pointer;
    background-color:  #137536;
    color: rgb(3, 3, 3);
  
  }

  .takeMeBackSimple {
color:green; 
text-decoration:underline;



  }