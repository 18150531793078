a {


text-decoration: none;
color: rgb(20, 20, 20);
font-size: inherit;
}

a:hover {


    text-decoration: none;
    color:#1db954;
    font-size: inherit;
    }