.TimerDisplayElements {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    flex-direction: row;
  }

  .Next-Song-Box {
    padding-top: 170px;


  }

  .songIsRatedBox {
    position: absolute;
    top: 200px;
    left: 10px;
    width: 210px;
  color: black;
    /* color:rgb(83, 83, 83); */
    height: 250px;
    padding-top: 150px; 
    /* margin-top: -50px; */
  
    background-color: rgb(240, 240, 240);
    /* text-align: center; */
    transform: translate(0%, -50%);

  }

  .Track-information-caru {


    max-width: 700px;
    min-width: 700px;
  }

  .Artist-information {

    max-width: 700px;
    min-width: 700px;

  }

