.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.header__right {
  display: flex;
  align-items: center;
  padding-right: 50px;
}

.header__left {
  flex: 0.5;
  display: flex;
 
  padding: 10px;
  border-radius: 30px;
  color:white;
  align-items: center;
}

/*muss in element oberhalb sein */
.song__thatWillBeAdded {

 display: block;
}

.header__left > input {
  border: none;
  width: 100%;
}

.header__right > h4 {
  margin-left: 10px;
}
