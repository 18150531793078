.login {
  display: grid;
  background-color: rgb(245, 245, 245);
  height: 100vh;
  place-items: center;
  color: rgb(43, 43, 43);
  font-size: 18px;
  margin-left: 10px;
  margin-right: 10px;
}

.smallFont {
  font-size: 14px;
}

.login > img {
  height: 100px;
}

.login > a {
  color: white;
  text-decoration: none;
  padding: 20px;
  border-radius: 99px;
  font-weight: 800;
  background-color: #1db954;
}

.login > a:hover {
  color: white;
  text-decoration: none;
  padding: 20px;
  border-radius: 99px;
  font-weight: 800;
  background-color: #178f41;
}